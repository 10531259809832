<template>
  <div>
    <v-dialog v-model="addMemberWSDialog" persistent width="700px">
      <v-toolbar dense class="gradient-bg white--text">
        <v-toolbar-title
          >Add Member to Workshift -
          {{ storeObj.workshift_name }}</v-toolbar-title
        >
        <v-spacer />
        <v-icon @click="$emit('clicked')">mdi-close</v-icon>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-row class="ma-3" no-gutters>
          <v-spacer />
          <v-col cols="4">
            <v-text-field
              v-model="search"
              label="Search Member"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :height="height"
          class="mx-3 overflow-y-auto mb-5 mt-n5"
          :headers="WSHeader"
          :items="WSItems"
          dense
          :search="search"
          :single-select="false"
          v-model="selected"
          item-key="user_id"
          hide-default-footer
          fixed-header
          show-select
          :items-per-page="1000"
        ></v-data-table>
        <v-card-text align="center">
          <v-btn
            @click="list_add_members_workshift('UPDATEUSERS')"
            class="gradient-bg2 white--text"
            small
            >save changes</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetAddingMemebersToWorkShift } from "@/graphql/mutations.js";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";

export default {
  props: {
    addMemberWSDialog: Boolean,
    storeObj: Object,
  },
  components: {
    Overlay,
    SnackBar,
  },
  data: () => ({
    WSHeader: [
      { text: "Member Name", value: "user_name" },
      { text: "Current Workshift", value: "workshift_name" },
    ],
    WSItems: [],
    search: "",
    selected: [],
    overlay: false,
    height: 0,
    SnackBarComponent: {},
  }),
  watch: {
    addMemberWSDialog(val) {
      this.height = window.innerHeight - 300;
      console.log(val);
      this.list_add_members_workshift("GETUSERS");
    },
  },
  methods: {
    async list_add_members_workshift(type) {
      this.overlay = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          action_workshift: type,
          workshift_id: this.storeObj.workshift_id,
        };
        if (type == "UPDATEUSERS") {
          inputParams.user_ids = this.selected.map((item) => item.user_id);
        }
        await API.graphql(
          graphqlOperation(GetAddingMemebersToWorkShift, {
            input: inputParams,
          })
        ).then((res) => {
          switch (type) {
            case "GETUSERS":
              var current_shift_users = JSON.parse(
                res.data.GetAddingMemebersToWorkShift
              ).workshift_users;
              this.WSItems = JSON.parse(
                res.data.GetAddingMemebersToWorkShift
              ).all_users;
              this.selected = current_shift_users;
              break;
            case "UPDATEUSERS":
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: JSON.parse(res.data.GetAddingMemebersToWorkShift)
                  .Message,
              };
              setTimeout(() => {
                this.$emit("clicked");
              }, 500);
              break;
            default:
              break;
          }
          this.overlay = false;
        });
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
</style>