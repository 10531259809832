<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
        <v-toolbar dense class="elevation-0">
          <!-- <v-toolbar-title><h3>Work Timings</h3></v-toolbar-title> -->
          <v-select
            v-model="status"
            :items="statusItems"
            outlined
            label="Filter By WorkShift Status"
            dense
            style="max-width: 200px"
            class="mt-7 mx-5"
          >
          </v-select>
          <v-spacer> </v-spacer>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
              v-show="status=='ACTIVE'"
                v-on="on"
                small
                class="gradient-bg white--text mr-2"
                @click="create_edit_shift('CREATE', null)"
              >
                <v-icon class="ml-n1 mr-1">mdi-plus</v-icon>CREATE SHIFT
              </v-btn>
            </template>
            <span class="white--text">Create Shift</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
      <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
        <div v-if="workshiftList.length != 0" class="mx-5 mt-5">
          <v-expansion-panels v-for="(work, idx) in workshiftList" :key="idx">
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-3">
                <v-row class="my-n3" no-gutters>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-toolbar color="grey lighten-3" dense elevation="0">
                      <span style="font-weight: bolder">
                        <v-icon small class="mt-n1 primary--text mr-2"
                          >mdi-star</v-icon
                        >
                        Workshift Name :
                        <span style="font-weight: normal">
                          {{ work.workshift_name }}
                        </span></span
                      >
                      <v-spacer />

                      <v-tooltip
                        v-if="work.workshift_status != 'DEACTIVE'"
                        bottom
                        color="primary"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="green white--text ml-2 "
                            x-small
                            @click="create_edit_shift('EDIT', work)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span class="white--text">Edit Workshift ?</span>
                      </v-tooltip>
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="work.is_default_workshift == true"
                            v-on="on"
                            :class="
                              work.is_default_workshift == true
                                ? ''
                                : 'gradient-bg white--text'
                            "
                            x-small
                            class="ml-2"
                            @click="activate_deactivate_shift(work)"
                          >
                            <v-icon small>{{
                              work.workshift_status != "DEACTIVE"
                                ? "mdi-delete"
                                : "mdi-redo"
                            }}</v-icon>
                          </v-btn>
                        </template>
                        <span class="white--text">{{
                          work.workshift_status != "DEACTIVE"
                            ? "Deactivate  Workshift ?"
                            : "Reactive Workshift ?"
                        }}</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="work.workshift_status != 'DEACTIVE'"
                        bottom
                        color="primary"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="green white--text ml-2"
                            x-small
                            @click="add_members_to_shift(work)"
                          >
                            <v-icon small
                              >mdi-account-multiple-plus-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span class="white--text"
                          >Add Members to workshift ?</span
                        >
                      </v-tooltip>
                    </v-toolbar>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-3" no-gutters >
                  <v-col cols="12" xs="3" md="3" sm="3"  lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      <h4>Day</h4>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" xs="3" md="3" sm="3"  lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      <h4>Start Time</h4>
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      <h4>End Time</h4>
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      <h4>WeekOff</h4>
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 2px; border-color: black"
                      class="mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0 mt-2" align="center">
                      MONDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0 mt-2" align="center">
                      {{ work.workshift_timings.Monday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0 mt-2" align="center">
                      {{ work.workshift_timings.Monday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Monday.weekly_off"
                        readonly
                        dense
                        class="mt-1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 1px"
                      class="mt-n3 mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      TUESDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Tuesday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Tuesday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Tuesday.weekly_off"
                        readonly
                        dense
                        class="mt-n1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 1px"
                      class="mt-n3 mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      WEDNESDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Wednesday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Wednesday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Wednesday.weekly_off"
                        readonly
                        dense
                        class="mt-n1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 1px"
                      class="mt-n3 mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      THURSDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Thursday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Thursday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Thursday.weekly_off"
                        readonly
                        dense
                        class="mt-n1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 1px"
                      class="mt-n3 mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      FRIDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Friday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Friday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Friday.weekly_off"
                        readonly
                        dense
                        class="mt-n1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 1px"
                      class="mt-n3 mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      SATURDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Saturday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Saturday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Saturday.weekly_off"
                        readonly
                        dense
                        class="mt-n1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                  <v-col cols="12" md="12" sm="12" xs="12" lg="12" xl="12">
                    <v-divider
                      style="border-width: 1px"
                      class="mt-n3 mx-0"
                    ></v-divider>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      SUNDAY
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Sunday.start_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="center">
                      {{ work.workshift_timings.Sunday.end_time }}
                    </v-card-text></v-col
                  >
                  <v-col cols="12" md="3" sm="3" xs="3" lg="3" xl="3">
                    <v-card-text class="ma-0 pa-0" align="end">
                      <v-checkbox
                        v-model="work.workshift_timings.Sunday.weekly_off"
                        readonly
                        dense
                        class="mt-n1"
                        style="margin-left: 150px"
                      ></v-checkbox> </v-card-text
                  ></v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-else>
          <v-card-text align="center"> <h1>Nothing to display</h1></v-card-text>
        </div>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <CreateShift
      :createEditShiftDialog="createEditShiftDialog"
      @back="get_relavent_data"
      :storeObj="storeObj"
    />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
    <AddMemberToWorkshift
      :addMemberWSDialog="addMemberWSDialog"
      @clicked="get_workshift_data"
      :storeObj="storeObj"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { activateAndReactiveaytedWorkshiftForOrganisation } from "@/graphql/mutations.js";
import { listWorkshiftForOrganisation } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
import CreateShift from "@/components/Settings/Cards/CreateShift.vue";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";
import AddMemberToWorkshift from "@/components/Dialogs/AddMemberToWorkshift.vue";

export default {
  components: {
    Overlay,
    SnackBar,
    CreateShift,
    DeleteDialog,
    AddMemberToWorkshift,
  },
  data: () => ({
    addMemberWSDialog: false,
    deleteLoading: false,
    confDeleteDialog: false,
    deleteColor: "",
    deleteIcon: "",
    deleteText: "",
    createEditShiftDialog: false,
    status: "ACTIVE",
    statusItems: ["ACTIVE", "DEACTIVE"],
    SnackBarComponent: {},
    overlay: false,
    workshiftList: [],
    storeObj: {},
  }),
  mounted() {
    this.get_work_shift_timings(this.status);
  },
  watch: {
    status(val) {
      this.get_work_shift_timings(val);
    },
  },
  methods: {
    get_workshift_data() {
      this.addMemberWSDialog = false;
      this.get_work_shift_timings(this.status);
    },
    add_members_to_shift(item) {
      this.storeObj = {};
      
      this.storeObj = item;
      this.addMemberWSDialog = true;
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.deactivate_reactivate_worktimings(this.deleteUserItem);
    },
    activate_deactivate_shift(item) {
      this.deleteUserItem = item;
      this.confDeleteDialog = true;
      this.deleteText =
        this.status == "ACTIVE"
          ? `Are you sure you want to De-Activate the shift ${item.workshift_name} ?`
          : `Are you sure you want to Re-Activate the shift ${item.workshift_name}`;
      this.deleteIcon =
        this.status == "ACTIVE" ? "mdi-account-off" : "mdi-account-check";
      this.deleteColor = this.status == "ACTIVE" ? "red" : "green";
    },
    async deactivate_reactivate_worktimings(item) {
      this.deleteLoading = true;
      try {
        await API.graphql(
          graphqlOperation(activateAndReactiveaytedWorkshiftForOrganisation, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              workshift_status: this.status == "ACTIVE" ? "DEACTIVE" : "ACTIVE",
              workshift_id: item.workshift_id,
            },
          })
        ).then((res) => {
          this.$store.commit("snackbar/showMessage", {
            content: JSON.parse(
              res.data.activateAndReactiveaytedWorkshiftForOrganisation
            ).Message,
            color: "green",
          });
          this.get_work_shift_timings(this.status);
          this.deleteLoading = false;
          this.confDeleteDialog = false;
        });
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.deleteLoading = false;
      }
    },
    get_relavent_data() {
      this.get_work_shift_timings(this.status);
      this.createEditShiftDialog = false;
    },
    create_edit_shift(action, item) {
      if (action == "CREATE") {
        this.storeObj.action = action;
      } else {
        this.storeObj = item;
        this.storeObj.action = action;
      }
      this.createEditShiftDialog = true;
      this.$forceUpdate();
    },
    async get_work_shift_timings(status) {
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(listWorkshiftForOrganisation, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              workshift_status: status,
            },
          })
        ).then((res) => {
          this.workshiftList = JSON.parse(
            res.data.listWorkshiftForOrganisation
          ).Items;
          // console.log("resmwrk", this.workshiftList);
          this.ShiftItems = this.workshiftList.filter(
            (val) => val.workshift_name
          );
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        this.workshiftList = [];
        console.log(err);
        this.overlay = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>