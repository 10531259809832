<template>
  <div class="mt-5">
    <v-dialog v-model="createEditShiftDialog" persistent width="800px">
      <v-row no-gutters>
        <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
          <v-toolbar dense class="gradient-bg2 white--text elevation-0">
            <v-toolbar-title>{{
              storeObj.action != undefined || storeObj.action != null
                ? storeObj.action == "CREATE"
                  ? "Create Shift"
                  : "Edit Shift"
                : ""
            }}</v-toolbar-title>
            <v-spacer> </v-spacer>

            <v-icon @click="$emit('back')" class="mr-n2 ml-n1 white--text"
              >mdi-close</v-icon
            >
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12" align="center">
          <v-card class="overflow-hidden" flat>
            <v-form ref="form">
              <v-row no-gutters>
                <v-col cols="12" md="1" sm="1" xs="1"></v-col>
                <v-col cols="12" md="10" sm="10" xs="10">
                  <v-text-field
                    v-model="ShiftName"
                    :label="
                      storeObj.action != undefined || storeObj.action != null
                        ? storeObj.action == 'CREATE'
                          ? 'Enter Shift Name *'
                          : 'Edit Shift Name *'
                        : ''
                    "
                    dense
                    class="mt-3"
                    :rules="[(v) => !!v || 'required']"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                  md="9"
                  xs="9"
                  lg="9"
                  xl="9"
                  class="mt-n5"
                ></v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  xs="3"
                  lg="3"
                  xl="3"
                  class="mt-n5"
                >
                  <v-card-text align="center" class="ml-3 my-0 py-0"
                    ><strong> Weekly Off</strong></v-card-text
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  xs="3"
                  lg="3"
                  xl="3"
                  class="mt-3"
                >
                  <strong> Monday </strong></v-col
                >
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  xs="3"
                  lg="3"
                  xl="3"
                  class="mt-3"
                >
                  <v-dialog
                    ref="MondayStartTime"
                    v-model="MondayStartDialog"
                    :return-value.sync="MondayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="MondayStartTime"
                        label="Start Time"
                        class="mx-2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="MondayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="MondayStartDialog"
                      v-model="MondayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="MondayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.MondayStartTime.save(MondayStartTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  xs="3"
                  lg="3"
                  xl="3"
                  class="mt-3"
                >
                  <v-dialog
                    ref="MondayEndTime"
                    v-model="MondayEndDialog"
                    :return-value.sync="MondayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-2"
                        v-model="MondayEndTime"
                        label="End Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        :disabled="MondayCheckBox"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="MondayEndDialog"
                      v-model="MondayEndTime"
                      full-width
              
                      format="24hr"
                    >
                    <!-- :min="MondayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="MondayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.MondayEndTime.save(MondayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  xs="3"
                  lg="3"
                  xl="3"
                  class="mt-3"
                >
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      class="mt-3 mr-16"
                      v-model="MondayCheckBox"
                    ></v-checkbox>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <strong>Tuesday </strong></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="TuesdayStartTime"
                    v-model="TuesdayStartDialog"
                    :return-value.sync="TuesdayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="TuesdayStartTime"
                        label="Start Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        class="mx-2"
                        :disabled="TuesdayCheckBox"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="TuesdayStartDialog"
                      v-model="TuesdayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="TuesdayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.TuesdayStartTime.save(TuesdayStartTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="TuesdayEndTime"
                    v-model="TuesdayEndDialog"
                    :return-value.sync="TuesdayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="TuesdayEndTime"
                        label="End Time"
                        class="mx-2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="TuesdayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="TuesdayEndDialog"
                      v-model="TuesdayEndTime"
                      full-width
              
                      format="24hr"
                    >
                    <!-- :min="TuesdayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="TuesdayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.TuesdayEndTime.save(TuesdayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      v-model="TuesdayCheckBox"
                      class="mr-16"
                    ></v-checkbox>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <strong> Wednesday </strong></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="WednesdayStartTime"
                    v-model="WednesdayStartDialog"
                    :return-value.sync="WednesdayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-2"
                        v-model="WednesdayStartTime"
                        label="Start Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="WednesdayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="WednesdayStartDialog"
                      v-model="WednesdayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="WednesdayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.WednesdayStartTime.save(WednesdayStartTime)
                        "
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="WednesdayEndTime"
                    v-model="WednesdayEndDialog"
                    :return-value.sync="WednesdayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-2"
                        v-model="WednesdayEndTime"
                        label="End Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="WednesdayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="WednesdayEndDialog"
                      v-model="WednesdayEndTime"
                  
                      full-width
                      format="24hr"
                    >
                    <!-- :min="WednesdayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="WednesdayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.WednesdayEndTime.save(WednesdayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      v-model="WednesdayCheckBox"
                      class="mr-16"
                    ></v-checkbox> </v-toolbar
                ></v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <strong>Thursday</strong>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="ThursdayStartTime"
                    v-model="ThursdayStartDialog"
                    :return-value.sync="ThursdayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-2"
                        v-model="ThursdayStartTime"
                        label="Start Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="ThursdayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="ThursdayStartDialog"
                      v-model="ThursdayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="ThursdayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.ThursdayStartTime.save(ThursdayStartTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="ThursdayEndTime"
                    v-model="ThursdayEndDialog"
                    :return-value.sync="ThursdayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-2"
                        v-model="ThursdayEndTime"
                        label="End Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="ThursdayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="ThursdayEndDialog"
                      v-model="ThursdayEndTime"
            
                      full-width
                      format="24hr"
                    >
                    <!-- :min="ThursdayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="ThursdayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.ThursdayEndTime.save(ThursdayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      v-model="ThursdayCheckBox"
                      class="mr-16"
                    ></v-checkbox> </v-toolbar
                ></v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <strong>Friday </strong></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="FridayStartTime"
                    v-model="FridayStartDialog"
                    :return-value.sync="FridayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="FridayStartTime"
                        label="Start Time"
                        class="mx-2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        :disabled="FridayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="FridayStartDialog"
                      v-model="FridayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="FridayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.FridayStartTime.save(FridayStartTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="FridayEndTime"
                    v-model="FridayEndDialog"
                    :return-value.sync="FridayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="FridayEndTime"
                        label="End Time"
                        class="mx-2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        dense
                        :disabled="FridayCheckBox"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="FridayEndDialog"
                      v-model="FridayEndTime"
        
                      full-width
                      format="24hr"
                    >
                    <!-- :min="FridayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="FridayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.FridayEndTime.save(FridayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      v-model="FridayCheckBox"
                      class="mr-16"
                    ></v-checkbox>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <strong> Saturday </strong></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="SaturdayStartTime"
                    v-model="SaturdayStartDialog"
                    :return-value.sync="SaturdayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="SaturdayStartTime"
                        label="Start Time"
                        class="mx-2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        :disabled="SaturdayCheckBox"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="SaturdayStartDialog"
                      v-model="SaturdayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="SaturdayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.SaturdayStartTime.save(SaturdayStartTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="SaturdayEndTime"
                    v-model="SaturdayEndDialog"
                    :return-value.sync="SaturdayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="SaturdayEndTime"
                        class="mx-2"
                        label="End Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        dense
                        :disabled="SaturdayCheckBox"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="SaturdayEndDialog"
                      v-model="SaturdayEndTime"
      
                      full-width
                      format="24hr"
                    >
                    <!-- :min="SaturdayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="SaturdayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.SaturdayEndTime.save(SaturdayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      v-model="SaturdayCheckBox"
                      class="mr-16"
                    ></v-checkbox>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <strong>Sunday </strong></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="SundayStartTime"
                    v-model="SundayStartDialog"
                    :return-value.sync="SundayStartTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="SundayStartTime"
                        label="Start Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        class="mx-2"
                        :disabled="SundayCheckBox"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="SundayStartDialog"
                      v-model="SundayStartTime"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="SundayStartDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.SundayStartTime.save(SundayStartTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-dialog
                    ref="SundayEndTime"
                    v-model="SundayEndDialog"
                    :return-value.sync="SundayEndTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="SundayEndTime"
                        label="End Time"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        class="mx-2"
                        dense
                        :disabled="SundayCheckBox"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="SundayEndDialog"
                      v-model="SundayEndTime"
                  
                      full-width
                      format="24hr"
                    >
                    <!-- :min="SundayStartTime" -->
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="SundayEndDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.SundayEndTime.save(SundayEndTime)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog></v-col
                >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-toolbar dense flat>
                    <v-spacer />
                    <v-checkbox
                      v-model="SundayCheckBox"
                      class="mr-16"
                    ></v-checkbox>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <v-card-text align="center">
                    <v-btn
                      @click="validate_updateworktimings"
                      :loading="isLoading"
                      class="gradient-bg white--text"
                      small
                      :disabled="disabled_value"
                    >
                      <v-icon class="mr-2">mdi-content-save</v-icon>
                      {{
                        storeObj.action != undefined || storeObj.action != null
                          ? storeObj.action == "CREATE"
                            ? "Create"
                            : "Save"
                          : ""
                      }}
                    </v-btn>
                    <v-card-text class="red--text" v-if="disabled_value"
                      ><b
                        ><u>NOTE</u>: Atleast 1 day of the shift must not be a
                        weekoff
                      </b></v-card-text
                    >
                  </v-card-text>
                </v-col>
              </v-row>
            </v-form>
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              <v-card-text
                style="font-size: 15px"
                class="ma-0 pa-0 mb-9 mt-n2"
                align="center"
              >
                <v-divider
                  class="mt-0 mb-2 mx-10"
                  style="border-width: 2px; border-color: black"
                />
                <b> NOTE :</b> If <b>End Time</b> is Earlier than
                <b>Start Time</b>, It is automatically considered as
                <b>End Time</b> for NEXT DAY
                <br />
                <div style="font-size: 12px">
                  <b>(Eg : </b>Monday Start Time = 06:00 & Monday End Time =
                  20:00, End Time of Monday will be considered on Tuesday
                  Morning 06:00)
                </div>
                <b></b>
                <v-divider
                  class="mt-2 mx-10"
                  style="border-width: 2px; border-color: black"
                />
              </v-card-text>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import {
  createWorkshiftForOrganisation,
  editWorkshiftForOrganisation,
} from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
export default {
  props: {
    createEditShiftDialog: Boolean,
    storeObj: Object,
  },
  mixins: [GetCurrentUserDetailsnew],
  components: {
    Overlay,
    SnackBar,
  },
  data: () => ({
    ShiftName: "",
    overlay: false,
    SnackBarComponent: {},
    CurrentView: "Default",
    LatestStartDialog: false,
    LatestStartTime: "10:00",
    MondayStartTime: "09:00",
    MondayStartDialog: false,
    MondayEndDialog: false,
    MondayEndTime: "18:00",
    TuesdayCheckBox: false,
    MondayCheckBox: false,
    TuesdayStartTime: "09:00",
    TuesdayStartDialog: false,
    TuesdayEndDialog: false,
    TuesdayEndTime: "18:00",
    WednesdayCheckBox: false,
    WednesdayStartTime: "09:00",
    WednesdayStartDialog: false,
    WednesdayEndDialog: false,
    WednesdayEndTime: "18:00",
    ThursdayCheckBox: false,
    ThursdayStartTime: "09:00",
    ThursdayStartDialog: false,
    ThursdayEndDialog: false,
    ThursdayEndTime: "18:00",
    FridayCheckBox: false,
    FridayStartTime: "09:00",
    FridayStartDialog: false,
    FridayEndDialog: false,
    FridayEndTime: "18:00",
    SaturdayCheckBox: false,
    SaturdayStartTime: "09:00",
    SaturdayStartDialog: false,
    SaturdayEndDialog: false,
    SaturdayEndTime: "14:00",
    SundayCheckBox: true,
    SundayStartTime: "09:00",
    SundayStartDialog: false,
    SundayEndDialog: false,
    SundayEndTime: "18:00",
    isLoading: false,
    workshiftdetails: {},
    GetCurrentOrgObject: {},
  }),
  computed: {
    disabled_value() {
      if (
        this.SundayCheckBox &&
        this.SaturdayCheckBox &&
        this.FridayCheckBox &&
        this.ThursdayCheckBox &&
        this.WednesdayCheckBox &&
        this.TuesdayCheckBox &&
        this.MondayCheckBox
      )
        return true;
      else return false;
    },
  },
  watch: {
    // GetCurrentOrgObject(val) {
    //   console.log("Check The User Object in ws", val);
    //   this.workshiftdetails = val[0].organisation_workshift;
    //   this.getWorkTimings();
    // },
    createEditShiftDialog(val) {
      // console.log("sstoree", this.storeObj.action);

      if (val == true) {
        if (this.storeObj.action == "EDIT") {
          // console.log("sstoree", this.storeObj.action);

          // console.log("ssss", this.storeObj.workshift_timings);
          this.ShiftName = this.storeObj.workshift_name;
          this.MondayStartTime =
            this.storeObj.workshift_timings.Monday.start_time;
          this.MondayEndTime = this.storeObj.workshift_timings.Monday.end_time;
          this.MondayCheckBox =
            this.storeObj.workshift_timings.Monday.weekly_off;
          this.TuesdayStartTime =
            this.storeObj.workshift_timings.Tuesday.start_time;
          this.TuesdayEndTime =
            this.storeObj.workshift_timings.Tuesday.end_time;
          this.TuesdayCheckBox =
            this.storeObj.workshift_timings.Tuesday.weekly_off;
          this.WednesdayStartTime =
            this.storeObj.workshift_timings.Wednesday.start_time;
          this.WednesdayEndTime =
            this.storeObj.workshift_timings.Wednesday.end_time;
          this.WednesdayCheckBox =
            this.storeObj.workshift_timings.Wednesday.weekly_off;
          this.ThursdayStartTime =
            this.storeObj.workshift_timings.Thursday.start_time;
          this.ThursdayEndTime =
            this.storeObj.workshift_timings.Thursday.end_time;
          this.ThursdayCheckBox =
            this.storeObj.workshift_timings.Thursday.weekly_off;
          this.FridayStartTime =
            this.storeObj.workshift_timings.Friday.start_time;
          this.FridayEndTime = this.storeObj.workshift_timings.Friday.end_time;
          this.FridayCheckBox =
            this.storeObj.workshift_timings.Friday.weekly_off;
          this.SaturdayStartTime =
            this.storeObj.workshift_timings.Saturday.start_time;
          this.SaturdayEndTime =
            this.storeObj.workshift_timings.Saturday.end_time;
          this.SaturdayCheckBox =
            this.storeObj.workshift_timings.Saturday.weekly_off;
          this.SundayStartTime =
            this.storeObj.workshift_timings.Sunday.start_time;
          this.SundayEndTime = this.storeObj.workshift_timings.Sunday.end_time;
          this.SundayCheckBox =
            this.storeObj.workshift_timings.Sunday.weekly_off;
        } else {
          this.MondayStartTime = "09:00";
          this.MondayStartDialog = false;
          this.MondayEndDialog = false;
          this.MondayEndTime = "18:00";
          this.TuesdayCheckBox = false;
          this.MondayCheckBox = false;
          this.TuesdayStartTime = "09:00";
          this.TuesdayStartDialog = false;
          this.TuesdayEndDialog = false;
          this.TuesdayEndTime = "18:00";
          this.WednesdayCheckBox = false;
          this.WednesdayStartTime = "09:00";
          this.WednesdayStartDialog = false;
          this.WednesdayEndDialog = false;
          this.WednesdayEndTime = "18:00";
          this.ThursdayCheckBox = false;
          this.ThursdayStartTime = "09:00";
          this.ThursdayStartDialog = false;
          this.ThursdayEndDialog = false;
          this.ThursdayEndTime = "18:00";
          this.FridayCheckBox = false;
          this.FridayStartTime = "09:00";
          this.FridayStartDialog = false;
          this.FridayEndDialog = false;
          this.FridayEndTime = "18:00";
          this.SaturdayCheckBox = false;
          this.SaturdayStartTime = "09:00";
          this.SaturdayStartDialog = false;
          this.SaturdayEndDialog = false;
          this.SaturdayEndTime = "14:00";
          this.SundayCheckBox = true;
          this.SundayStartTime = "09:00";
          this.SundayStartDialog = false;
          this.SundayEndDialog = false;
          this.FSundayEndTime = "18:00";
          this.ShiftName = "";
          this.$refs.form.resetValidation();
        }
      }
    },

    MondayStartTime(val) {
      if (val == 0) {
        this.MondayStartTime = "09:00";
      }
    },
    MondayEndTime(val) {
      if (val == 0) {
        this.MondayEndTime = "18:00";
      }
    },
    TuesdayStartTime(val) {
      if (val == 0) {
        this.TuesdayStartTime = "09:00";
      }
    },
    TuesdayEndTime(val) {
      if (val == 0) {
        this.TuesdayEndTime = "18:00";
      }
    },
    WednesdayStartTime(val) {
      if (val == 0) {
        this.WednesdayStartTime = "09:00";
      }
    },
    WednesdayEndTime(val) {
      if (val == 0) {
        this.WednesdayEndTime = "18:00";
      }
    },
    ThursdayStartTime(val) {
      if (val == 0) {
        this.ThursdayStartTime = "09:00";
      }
    },
    ThursdayEndTime(val) {
      if (val == 0) {
        this.ThursdayEndTime = "18:00";
      }
    },
    FridayStartTime(val) {
      if (val == 0) {
        this.FridayStartTime = "09:00";
      }
    },
    FridayEndTime(val) {
      if (val == 0) {
        this.FridayEndTime = "18:00";
      }
    },
    SaturdayStartTime(val) {
      if (val == 0) {
        this.SaturdayStartTime = "09:00";
      }
    },
    SaturdayEndTime(val) {
      if (val == 0) {
        this.SaturdayEndTime = "18:00";
      }
    },
    SundayStartTime(val) {
      if (val == 0) {
        this.SundayStartTime = "09:00";
      }
    },
    SundayEndTime(val) {
      if (val == 0) {
        this.SundayEndTime = "18:00";
      }
    },
    SaturdayCheckBox(val) {
      if (val == false) {
        this.SaturdayStartTime = "09:30";
        this.SaturdayEndTime = "14:30";
      }
    },
    SundayCheckBox(val) {
      if (val == false) {
        this.SundayStartTime = "09:30";
        this.SundayEndTime = "18:30";
      }
    },
    MondayCheckBox(val) {
      if (val == false) {
        this.MondayStartTime = "09:30";
        this.MondayEndTime = "18:30";
      }
    },
    TuesdayCheckBox(val) {
      if (val == false) {
        this.TuesdayStartTime = "09:30";
        this.TuesdayEndTime = "18:30";
      }
    },
    WednesdayCheckBox(val) {
      if (val == false) {
        this.WednesdayStartTime = "09:30";
        this.WednesdayEndTime = "18:30";
      }
    },
    ThursdayCheckBox(val) {
      if (val == false) {
        this.ThursdayStartTime = "09:30";
        this.ThursdayEndTime = "18:30";
      }
    },
    FridayCheckBox(val) {
      if (val == false) {
        this.FridayStartTime = "09:30";
        this.FridayEndTime = "18:30";
      }
    },
  },
  methods: {
    validate_updateworktimings() {
      if (this.$refs.form.validate()) {
        this.updateworktimings();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Shift Name",
        };
      }
    },
    async updateworktimings() {
      this.isLoading = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          monday_weekly_off: this.MondayCheckBox,
          tuesday_weekly_off: this.TuesdayCheckBox,
          wednesday_weekly_off: this.WednesdayCheckBox,
          thursday_weekly_off: this.ThursdayCheckBox,
          friday_weekly_off: this.FridayCheckBox,
          saturday_weekly_off: this.SaturdayCheckBox,
          sunday_weekly_off: this.SundayCheckBox,
          monday_start_time: this.MondayStartTime,
          monday_end_time: this.MondayEndTime,
          tuesday_start_time: this.TuesdayStartTime,
          tuesday_end_time: this.TuesdayEndTime,
          wednesday_start_time: this.WednesdayStartTime,
          wednesday_end_time: this.WednesdayEndTime,
          thursday_start_time: this.ThursdayStartTime,
          thursday_end_time: this.ThursdayEndTime,
          friday_start_time: this.FridayStartTime,
          friday_end_time: this.FridayEndTime,
          saturday_start_time: this.SaturdayStartTime,
          saturday_end_time: this.SaturdayEndTime,
          sunday_start_time: this.SundayStartTime,
          sunday_end_time: this.SundayEndTime,
          workshift_name: this.ShiftName,
          user_email_id: this.$store.getters.get_useremail,
        };
        if (this.storeObj.action == "EDIT") {
          inputParams.workshift_id = this.storeObj.workshift_id;
          delete inputParams.user_email_id;
        }
        var api_name =
          this.storeObj.action == "CREATE"
            ? createWorkshiftForOrganisation
            : editWorkshiftForOrganisation;
        await API.graphql(
          graphqlOperation(api_name, {
            input: inputParams,
          })
        ).then((res) => {
          var temp =
            this.storeObj.action == "CREATE"
              ? JSON.parse(res.data.createWorkshiftForOrganisation).Message
              : JSON.parse(res.data.editWorkshiftForOrganisation).Message;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: temp,
          };
          // this.overlay = true;
          // this.GetCurrentUserDetailsnew();
          setTimeout(() => {
            this.$emit("back");
            this.isLoading = false;
          }, 500);
        });
      } catch (e) {
        console.log("e", e);
        this.isLoading = false;
        this.overlay = false;
        this.$store.commit("snackbar/showMessage", {
          content: "Unable to update Work Timings! Try again after sometime!",
          color: "red",
        });
      }
    },

    async getWorkTimings() {
      this.overlay = true;
      // console.log("asdasdasddas", this.workshiftdetails);
      this.MondayStartTime = this.workshiftdetails.Monday.start_time;
      this.MondayEndTime = this.workshiftdetails.Monday.end_time;
      this.MondayCheckBox = this.workshiftdetails.Monday.weekly_off;
      this.TuesdayStartTime = this.workshiftdetails.Tuesday.start_time;
      this.TuesdayEndTime = this.workshiftdetails.Tuesday.end_time;
      this.TuesdayCheckBox = this.workshiftdetails.Tuesday.weekly_off;
      this.WednesdayStartTime = this.workshiftdetails.Wednesday.start_time;
      this.WednesdayEndTime = this.workshiftdetails.Wednesday.end_time;
      this.WednesdayCheckBox = this.workshiftdetails.Wednesday.weekly_off;
      this.ThursdayStartTime = this.workshiftdetails.Thursday.start_time;
      this.ThursdayEndTime = this.workshiftdetails.Thursday.end_time;
      this.ThursdayCheckBox = this.workshiftdetails.Thursday.weekly_off;
      this.FridayStartTime = this.workshiftdetails.Friday.start_time;
      this.FridayEndTime = this.workshiftdetails.Friday.end_time;
      this.FridayCheckBox = this.workshiftdetails.Friday.weekly_off;
      this.SaturdayStartTime = this.workshiftdetails.Saturday.start_time;
      this.SaturdayEndTime = this.workshiftdetails.Saturday.end_time;
      this.SaturdayCheckBox = this.workshiftdetails.Saturday.weekly_off;
      this.SundayStartTime = this.workshiftdetails.Sunday.start_time;
      this.SundayEndTime = this.workshiftdetails.Sunday.end_time;
      this.SundayCheckBox = this.workshiftdetails.Sunday.weekly_off;
      this.overlay = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style>
.size {
  font-size: 19px;
  font-weight: bold;
}
</style>
